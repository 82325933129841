import './polyfills';

import Vue from 'vue';
// import ls from '@livesession/sdk';

import App from './App.vue';
import '@mdi/font/css/materialdesignicons.css';
import Buefy from 'buefy';
import VueSlider from 'vue-slider-component';
import store from './store/store';
import i18n from './languages/i18n';

import './directives.js';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import 'flag-icons/css/flag-icons.min.css';
import 'balloon-css/balloon.min.css';

// const lsOptions = {};
// if (process.env.NODE_ENV === 'production') {
//   lsOptions.rootHostname = '.benders.se';
// } else if (process.env.NODE_ENV === 'staging') {
//   lsOptions.rootHostname = '.benders.duvadev.se';
// }
// ls.init('3fcf25c4.feeecf3f', lsOptions, { devMode: process.env.NODE_ENV === 'development' });
// ls.newPageView();

Vue.component('VueSlider', VueSlider);

Vue.use(Buefy);

Vue.config.productionTip = process.env.NODE_ENV === 'development';

new Vue({
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
